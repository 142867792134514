
import { Vue, Component, Watch } from 'vue-property-decorator'
import { SearchBar, SearchToken } from '@/components/search-bar'
import { NavigationTabs, SearchTabs } from '@/components/navigation-tabs'
import { SeriesResults } from '@/components/search-results/series-results'
import { SearchParams } from '@/global-types'
import { updateRouter } from '@/components/search-results/series-results/utils'
import { updateSearchParams } from '@/utils/utils'
import { SeriesSuggestions } from '@/components/serials-suggestions'

@Component({
  components: {
    NavigationTabs,
    SearchBar,
    SeriesResults,
    SeriesSuggestions,
  },
})
export default class SeriesSearch extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  searchText = ''
  searchParams: SearchParams = {
    query: '',
    pageNumber: 1,
    pageSize: 20,
    sortBy: 'relevance',
    orderBy: 'asc',
    facets: '',
  }

  readonly seriesTab = SearchTabs.series
  readonly searchFields: SearchToken[] = [
    { name: 'Series Abbreviation', value: 'st' },
    { name: 'Series Name', value: 'se', format: 'series', description: 'The name the series' },
    { name: 'Translated Title', value: 'tt', advanced: true, description: 'The title of a paper translated into English' },
    { name: 'ISSN', value: 'issn' },
    { name: 'Publisher', value: 'p' },
  ]

  readonly searchOperators: SearchToken[] = [
    { name: 'logical AND', value: 'AND', example: 'a AND b' },
    { name: 'logical OR', value: 'OR', example: 'a OR b' },
    { name: 'logical NOT', value: 'NOT', example: 'NOT a' },
  ]

  created() {
    const localPageSize = localStorage.getItem(this.$route.name + 'PageSize')
    if (localPageSize) {
      this.searchParams.pageSize = parseInt(localPageSize)
    }
    this.updateDataFromURLParams()
  }

  async mounted() {
    document.title = 'Series Search - MathSciNet'
  }

  @Watch('$route', { immediate: true })
  onRouteChange() {
    document.title = 'Series Search - MathSciNet'
    this.updateDataFromURLParams()
  }

  get internalAccess() {
    return this.$store.state.AppConfigModule.appConfig.internal
  }

  // get searchText() {
  //   return this.$store.state.SearchTextModule.seriesSearchText
  // }

  // set searchText(value) {
  //   this.$store.commit(UPDATE_SERIES_SEARCH_TEXT, value)
  // }

  updateDataFromURLParams() {
    this.searchParams = updateSearchParams(this.$route, this.searchParams)
    if (this.searchParams.query) {
      this.searchText = this.searchParams.query
    }
  }

  async search(searchText: string) {
    if (searchText.trim().length > 0) {
      this.searchParams.pageNumber = 1
      this.$nextTick(() => {
        updateRouter(this.$router)({ ...this.searchParams, query: '' })
        updateRouter(this.$router)({ ...this.searchParams, query: searchText, pageNumber: 1 })
      })
    }
  }

  clear() {
    this.searchText = ''
    this.searchParams.query = ''
    this.$route.query.query = ''
  }
}
